<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="multiproductWidgetCustomisationLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="multiproductWidgetCustomisationError" class="alert alert-danger">
        <template v-if="multiproductWidgetCustomisationError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internalServerError') }}
        </template>
      </div>
      <div v-else>
        <div class="mb-2 d-flex justify-content-between align-items-end">
          <h5>{{ multiproductWidgetCustomisation.name }}</h5>
          <router-link
            :to="{ name: 'editMultiproductWidgetCustomisation', params: { id: $route.params.id } }"
            class="btn btn-warning">
            {{ $t('shared.actions.edit') }}
          </router-link>
        </div>

        <persistent-tabs content-class="mt-3">
          <!-- Infos tab -->
          <b-tab :title="$t('views.customisations.tabs.infos')">
            <div class="form-group row">
              <label for="input-name" class="col-sm-2">{{ $t('attributes.customisation.name') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                {{ multiproductWidgetCustomisation.name }}
              </div>
            </div>

            <div class="form-group row">
              <label for="multiselect-groups" class="col-sm-2">{{ $t('attributes.customisation.group') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <router-link :to="{ name: 'group', params: { id: multiproductWidgetCustomisation.group.id } }">
                  <region-flag :code="multiproductWidgetCustomisation.group.region.code" />
                  {{ multiproductWidgetCustomisation.group.name }}
                </router-link>
              </div>
            </div>

            <div class="form-group row">
              <label for="default-checkbox" class="col-sm-2">{{ $t('attributes.customisation.default') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.default" />
              </div>
            </div>

            <div class="form-group row">
              <label for="multiselect-domains" class="col-sm-2">{{ $t('attributes.customisation.domains') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <ul class="mb-0">
                  <li v-for="domain in multiproductWidgetCustomisation.domains" :key="domain">{{ domain }}</li>
                </ul>
              </div>
            </div>
          </b-tab>

          <!-- Features tab -->
          <b-tab :title="$t('views.customisations.tabs.features')">
            <div class="form-group row">
              <label for="select-products-layout" class="col-sm-3">{{ $t('attributes.multiproductWidgetCustomisation.productsLayout') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <b-form-radio-group
                  id="btn-radios-products-layout"
                  :checked="multiproductWidgetCustomisation.productsLayout"
                  buttons
                  button-variant="outline-primary">
                  <b-form-radio
                    v-for="value in ['grid', 'swiper']"
                    :key="value"
                    :value="value"
                    :disabled="multiproductWidgetCustomisation.productsLayout !== value">
                    <div class="d-flex flex-column align-items-center">
                      <big>
                        <svg v-if="value === 'grid'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa"><path fill="currentColor" d="M149.333 56v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zm181.334 240v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm32-240v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24zm-32 80V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm-205.334 56H24c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm386.667-56H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm0 160H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zM181.333 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24z"/></svg>
                        <svg v-else-if="value === 'swiper'" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 680 512" class="svg-inline--fa"><path fill="currentColor" d="M 439.7871 296 v -106.4 c 0 -17.6292 -14.2908 -31.92 -31.92 -31.92 h -134.7742 c -17.6292 0 -31.92 14.2908 -31.92 31.92 v 106.4 c 0 17.6292 14.2908 31.92 31.92 31.92 h 134.7729 c 17.6305 0 31.9213 -14.2908 31.9213 -31.92 Z M 651.7 250 l -102.9608 103.8025 c -3.2964 3.2964 -8.6268 3.2964 -11.9233 0 l -13.8869 -13.8869 c -3.2964 -3.2964 -3.2964 -8.6268 0 -11.9233 l 83.1121 -83.9536 l -83.1121 -83.9536 c -3.2964 -3.2964 -3.2964 -8.6268 0 -11.9233 l 13.8869 -13.8869 c 3.2964 -3.2964 8.6268 -3.2964 11.9233 0 l 102.9608 103.8025 c 3.2964 3.2964 3.2964 8.6268 0 11.9233 z M 26.6 250 l 102.96 103.8018 c 3.2957 3.2957 8.6264 3.2957 11.9241 0 l 13.8872 -13.8872 c 3.2957 -3.2957 3.2957 -8.6264 0 -11.9241 l -83.1117 -83.9536 l 83.1117 -83.9536 c 3.2957 -3.2957 3.2957 -8.6264 0 -11.9241 l -13.8872 -13.8872 c -3.2957 -3.2957 -8.6264 -3.2957 -11.9241 0 l -102.96 103.8018 c -3.2957 3.2957 -3.2957 8.6264 0 11.9241 z" class=""></path></svg>
                      </big>
                      <small>{{ $t(`attributes.multiproductWidgetCustomisation.productsLayouts.${value}`) }}</small>
                    </div>
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <div class="form-group row">
              <label for="btn-radios-layout" class="col-sm-3">{{ $t('attributes.customisation.layout') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <b-form-radio-group
                  id="btn-radios-layout"
                  :checked="multiproductWidgetCustomisation.layout"
                  buttons
                  button-variant="outline-primary">
                  <b-form-radio
                    v-for="value in ['columns', 'tabs']"
                    :key="value"
                    :value="value"
                    :disabled="multiproductWidgetCustomisation.layout !== value">
                    <div class="d-flex flex-column align-items-center">
                      <big>
                        <svg v-if="value === 'columns'" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-table fa-w-16"><path fill="currentColor" d="M 464 32 H 48 C 21.49 32 0 53.49 0 80 v 352 c 0 26.51 21.49 48 48 48 h 416 c 26.51 0 48 -21.49 48 -48 V 80 c 0 -26.51 -21.49 -48 -48 -48 z M 160 448 h -112 c -8.837 0 -16 -7.163 -16 -16 v -250 h 128 v 266 z M 320 448 h -128 v -266 h 128 v 266 z M 480 182 v 250 c 0 8.837 -7.163 16 -16 16 h -112 v -266 h 128 z M 480 152 h -448 v -72 h 448 v 72 z" class=""></path></svg>
                        <svg v-else-if="value === 'tabs'" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-table fa-w-16"><path fill="currentColor" d="M 464 32 H 48 C 21.49 32 0 53.49 0 80 v 352 c 0 26.51 21.49 48 48 48 h 416 c 26.51 0 48 -21.49 48 -48 V 80 c 0 -26.51 -21.49 -48 -48 -48 z M 160 448 H 48 c -8.837 0 -16 -7.163 -16 -16 v -160 h 448 v 160 c 0 8.837 -7.163 16 -16 16 h -416 z M 32 184 h 128 v 56 h -128 z M 192 184 h 128 v 56 h -128 z M 352 184 h 128 v 56 h -128 z M 32 80 h 448 v 72 h -448 z" class=""></path></svg>
                      </big>
                      <small>{{ $t(`attributes.customisation.layouts.${value}`) }}</small>
                    </div>
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <div class="form-group row">
              <label for="show-reference-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showReference') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.showReference" />
              </div>
            </div>

            <div class="form-group row">
              <label for="is-vertically-centered-checkbox" class="col-sm-3">{{ $t('attributes.customisation.isVerticallyCentered') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.isVerticallyCentered" />
              </div>
            </div>

            <div class="form-group row">
              <label
                for="retailer-price-filter-checkbox"
                class="col-sm-3"
                v-b-tooltip.hover :title="$t('views.products.tooltip.retailerPriceFilter')">
                {{ $t('attributes.customisation.retailerPriceFilter') }}
                <sup>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
                </sup>
              </label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.retailerPriceFilter" />
              </div>
            </div>

            <div class="form-group row">
              <label for="map-first-checkbox" class="col-sm-3">{{ $t('attributes.customisation.mapFirst') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.mapFirst" />
              </div>
            </div>

            <div class="form-group row">
              <label for="map-first-checkbox" class="col-sm-3">{{ $t('attributes.customisation.displayStorePhoneOnList') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.displayStorePhoneOnList" />
              </div>
            </div>

            <div class="form-group row">
              <label for="map-first-checkbox" class="col-sm-3">{{ $t('attributes.customisation.displayPhoneInInfoWindow') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.displayPhoneInInfoWindow" />
              </div>
            </div>

            <div class="form-group row">
              <label for="map-first-checkbox" class="col-sm-3">{{ $t('attributes.customisation.displayLoadingPlaceholders') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.displayLoadingPlaceholders" />
              </div>
            </div>

            <div class="form-group row">
              <label for="map-first-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showDriveDirections') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.showDriveDirections" />
              </div>
            </div>

            <div class="form-group row">
              <label for="browser-geolocation-checkbox" class="col-sm-3">{{ $t('attributes.customisation.browserGeolocation') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.browserGeolocation" />
              </div>
            </div>

            <div class="form-group row">
              <label
                for="use-geoip-checkbox"
                class="col-sm-3"
              >
                <span v-b-tooltip.hover :title="$t('views.products.tooltip.geoIp')">
                  {{ $t('attributes.customisation.useGeoip') }}
                  <sup>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" class=""></path></svg>
                  </sup>
                </span>
              </label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.useGeoip" />
              </div>
            </div>

            <div class="form-group row">
              <label for="side-opening-checkbox" class="col-sm-3">{{ $t('attributes.customisation.isSideOpening') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.isSideOpening" />
              </div>
            </div>

            <div class="form-group row">
              <label for="delivery-retailer-large-img-checkbox" class="col-sm-3">{{ $t('attributes.customisation.deliveryRetailerLargeImg') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.deliveryRetailerLargeImg" />
              </div>
            </div>

            <div class="form-group row">
              <label for="product-filters-multiple-checkbox" class="col-sm-3">{{ $t('attributes.multiproductWidgetCustomisation.productFiltersMultiple') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.productFiltersMultiple" />
              </div>
            </div>

            <div class="form-group row">
              <label for="slider-search-radius" class="col-sm-3">{{ $t('attributes.customisation.radius') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                  {{ multiproductWidgetCustomisation.radius }} km
              </div>
            </div>

            <div class="form-group row">
              <label for="btn-radios-loader-type" class="col-sm-3">{{ $t('attributes.customisation.retailersLoaderType') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <b-form-radio-group
                  id="btn-radios-loader-type"
                  :checked="multiproductWidgetCustomisation.retailersLoaderType"
                  buttons
                  button-variant="outline-primary">
                  <b-form-radio
                    v-for="value in ['placeholder', 'spinner']"
                    :key="value"
                    :value="value"
                    :disabled="multiproductWidgetCustomisation.retailersLoaderType !== value">
                    <div class="d-flex flex-column align-items-center">
                      <big>
                        <svg v-if="value === 'placeholder'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="svg-inline--fa"><path d="M5 3a2 2 0 0 0-2 2"/><path d="M19 3a2 2 0 0 1 2 2"/><path d="M21 19a2 2 0 0 1-2 2"/><path d="M5 21a2 2 0 0 1-2-2"/><path d="M9 3h1"/><path d="M9 21h1"/><path d="M14 3h1"/><path d="M14 21h1"/><path d="M3 9v1"/><path d="M21 9v1"/><path d="M3 14v1"/><path d="M21 14v1"/><line x1="7" x2="15" y1="8" y2="8"/><line x1="7" x2="17" y1="12" y2="12"/><line x1="7" x2="13" y1="16" y2="16"/></svg>
                        <svg v-else-if="value === 'spinner'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="svg-inline--fa"><path d="M21 12a9 9 0 1 1-6.219-8.56"/></svg>
                      </big>
                      <small>{{ $t(`attributes.customisation.retailersLoaderTypes.${value}`) }}</small>
                    </div>
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <div class="form-group row">
              <label for="use-pikastream-checkbox" class="col-sm-3">{{ $t('attributes.customisation.usePikastream') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.usePikastream" />
              </div>
            </div>

            <hr>

            <h5 class="mb-3">{{ $t('views.customisations.additionalFields') }}</h5>

            <div class="form-group row">
              <label for="price-checkbox" class="col-sm-3">{{ $t('attributes.customisation.price') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <dot :active="multiproductWidgetCustomisation.price" />
              </div>
            </div>

            <template v-if="multiproductWidgetCustomisation.layout === 'tabs'">
              <div class="form-group row">
                <label for="show-shipping-cost-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showShippingCost') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <dot :active="multiproductWidgetCustomisation.showShippingCost" />
                </div>
              </div>

              <div class="form-group row">
                <label for="show-shipping-delay-checkbox" class="col-sm-3">{{ $t('attributes.customisation.showShippingDelay') }}</label>
                <div class="col-lg-4 col-sm-6 col-12">
                  <dot :active="multiproductWidgetCustomisation.showShippingDelay" />
                </div>
              </div>
            </template>
          </b-tab>

          <!-- Style tab -->
          <b-tab :title="$t('views.customisations.tabs.style')">
            <div class="form-group row">
              <label for="input-color" class="col-sm-2">{{ $t('attributes.customisation.color') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                {{ multiproductWidgetCustomisation.color }}
              </div>
            </div>

            <div class="form-group row">
              <label for="input-border-radius" class="col-sm-2">{{ $t('attributes.customisation.borderRadius') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                {{ multiproductWidgetCustomisation.borderRadius }}
              </div>
            </div>

            <div class="form-group row">
              <label for="font-input" class="col-sm-2 col-form-label">{{ $t('attributes.customisation.font') }}</label>
              <div class="col-sm-10 col-12">
                <div v-if="multiproductWidgetCustomisation.font.url" class="mb-3">
                  <a :href="multiproductWidgetCustomisation.font.url" class="btn btn-primary" target="_blank">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file fa-w-12"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z" class=""></path></svg>
                    {{ filenameFromUrl(multiproductWidgetCustomisation.font.url) }}
                  </a>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="logo-input" class="col-sm-2">{{ $t('attributes.customisation.logo') }}</label>
              <div class="col-sm-10 col-12">
                <div v-if="multiproductWidgetCustomisation.logo.url" class="border mb-3 d-inline-block">
                  <img :src="multiproductWidgetCustomisation.logo.url" class="img-fluid">
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Retailers tab -->
          <b-tab :title="$t('views.customisations.tabs.retailers')">
            <!-- Ordered deliveries -->
            <div class="form-group row">
              <label class="col-sm-2">{{ $t('attributes.customisation.orderedDeliveryIds') }}</label>
              <div class="col-sm-10 col-12">
                <div v-if="multiproductWidgetCustomisation.orderedDeliveries.length === 0" class="alert alert-warning" role="alert">
                  {{ $t('shared.warnings.noRetailer') }}
                </div>
                <table class="table table-hover" v-else>
                  <thead>
                    <tr>
                      <th class="th-shrink">{{ $t('attributes.retailer.id') }}</th>
                      <th>{{ $t('attributes.retailer.name') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="retailer in multiproductWidgetCustomisation.orderedDeliveries" :key="retailer.id">
                      <th>{{ retailer.id }}</th>
                      <td>
                        <div class="gutter-x-xs">
                          <region-flag :code="retailer.region.code" />
                          <img :src="retailer.img_small_url" style="max-height: 20px;">
                          {{ retailer.name }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Delivery tab sort -->
            <div class="form-group row">
              <label class="col-sm-2">{{ $t('attributes.customisation.deliveryTabSort') }}</label>
              <div class="col-sm-10 col-12">
                <b-form-radio-group
                  id="btn-radios-delivery-tab-sort"
                  :checked="multiproductWidgetCustomisation.deliveryTabSort"
                  stacked
                  disabled
                >
                  <b-form-radio
                    v-for="value in deliveryTabSorts"
                    :key="value"
                    :value="value"
                  >
                    {{ $t(`attributes.customisation.deliveryTabSorts.${value}`) }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </b-tab>

          <!-- Texts tab -->
          <b-tab :title="$t('views.customisations.tabs.texts')">
            <b-tabs content-class="mt-3" v-if="multiproductWidgetCustomisation.texts">
              <b-tab
                v-for="(translations, locale) in multiproductWidgetCustomisation.texts"
                :key="locale">
                <template v-slot:title>
                  <region-flag :code="locale.slice(-2)" />
                  {{ locale }}
                </template>

                <div
                  v-for="(textKey, value) in translations"
                  :key="textKey"
                  class="form-group row">
                  <label :for="`input-${textKey}`" class="col-sm-2">{{ textKey }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">{{ value }}</div>
                </div>
              </b-tab>
            </b-tabs>
          </b-tab>

          <!-- Tracking tab -->
          <b-tab :title="$t('views.customisations.tabs.tracking')">
            <div class="form-group row">
              <label for="select-tracking-consent-request" class="col-sm-2 col-form-label">{{ $t('attributes.customisation.trackingConsentRequest') }}</label>
                <b-form-radio-group
                  id="btn-radios-tracking-consent-request"
                  v-model="multiproductWidgetCustomisation.trackingConsentRequest"
                  stacked
                  disabled
                >
                  <b-form-radio
                    v-for="value in trackingConsentRequests"
                    :key="value"
                    :value="value"
                  >
                    {{ $t(`attributes.customisation.trackingConsentRequests.${value}`) }}
                    <small class="form-text text-muted mb-2">{{ $t(`attributes.customisation.trackingConsentRequestsHelp.${value}`) }}</small>
                  </b-form-radio>
                </b-form-radio-group>
            </div>

            <div class="form-group row">
              <label for="input-trackers-html" class="col-sm-4 col-form-label">{{ $t('attributes.customisation.trackersHtml') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '60vh'}"
                  v-model="multiproductWidgetCustomisation.trackersHtml"
                  lang="html"
                  :options="editorOptions"
                ></AceEditor>
              </div>
            </div>
          </b-tab>

          <!-- CSS tab -->
          <b-tab :title="$t('views.customisations.tabs.css')">
            <div class="form-group row">
              <label for="input-raw-css" class="col-sm-4 col-form-label">{{ $t('attributes.customisation.rawCss') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '60vh' }"
                  v-model="multiproductWidgetCustomisation.rawCss"
                  lang="css"
                  :options="editorOptions"
                ></AceEditor>
              </div>
            </div>
          </b-tab>

          <!-- JS tab -->
          <b-tab :title="$t('views.customisations.tabs.javascript')">
            <div class="form-group row">
              <label for="input-raw-js" class="col-sm-4 col-form-label">{{ $t('attributes.customisation.rawJs') }}</label>
              <div class="col-12 mt-2">
                <AceEditor
                  :style="{ height: '60vh' }"
                  v-model="multiproductWidgetCustomisation.rawJs"
                  lang="javascript"
                  :options="editorOptions"
                ></AceEditor>
              </div>
            </div>
          </b-tab>
        </persistent-tabs>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from '../shared/Dot.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import { BTabs, BTab, BFormRadioGroup, BFormRadio, VBTooltip } from 'bootstrap-vue'
import AceEditor from 'vue2-ace-editor'
import PersistentTabs from '../shared/PersistentTabs.vue'
import 'brace/theme/chrome'
import 'brace/ext/language_tools'
import 'brace/mode/html'
import 'brace/mode/javascript'
import 'brace/mode/less'

export default {
  components: { MdSpinner, Dot, RegionFlag, BTabs, BTab, BFormRadioGroup, BFormRadio, AceEditor, PersistentTabs },
  directives: { 'b-tooltip': VBTooltip },
  props: ['action'],
  data: function() {
    return {
      editorOptions: {
        useWorker: false,
        tabSize: 2,
        fontSize: '14px',
        highlightActiveLine: true,
        displayIndentGuides: true,
        showPrintMargin: false,
        readOnly: true
      },
      multiproductWidgetCustomisation: null,
      multiproductWidgetCustomisationLoading: false,
      multiproductWidgetCustomisationError: null,
      deliveryTabSorts: ['nationalStockFirst', 'mixedNationalStockPriority'],
      trackingConsentRequests: ['none', 'banner']
    }
  },
  methods: {
    // Get filename from url
    filenameFromUrl: function(url) {
      return new URL(url).pathname.split('/').pop()
    },
    // Load data
    loadData: function() {
      this.multiproductWidgetCustomisationLoading = true
      this.multiproductWidgetCustomisationError = null
      this.retailersLoading = true
      this.retailersError = null

      const query = `query multiproductWidgetCustomisationsShow ($id: Int!) {
        multiproductWidgetCustomisation(id: $id) {
          id
          name
          default
          domains
          productsLayout
          layout
          price
          showReference
          isVerticallyCentered
          retailerPriceFilter
          mapFirst
          displayStorePhoneOnList
          displayPhoneInInfoWindow
          displayLoadingPlaceholders
          showDriveDirections
          browserGeolocation
          useGeoip
          showShippingCost
          showShippingDelay
          color
          borderRadius
          font
          logo
          orderedDeliveries {
            id
            name
            region {
              id
              code
            }
          }
          deliveryTabSort
          texts
          trackersHtml
          trackingConsentRequest
          rawCss
          rawJs
          group {
            id
            name
            active
            region {
              id
              code
            }
          }
          radius
          isSideOpening
          deliveryRetailerLargeImg
          productFiltersMultiple
          retailersLoaderType
          usePikastream
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.multiproductWidgetCustomisationLoading = false
          this.retailersLoading = false

          if (data.errors) {
            this.multiproductWidgetCustomisationError = { status: res.status, errors: data.errors }
            this.retailersError = { status: res.status, errors: data.errors }
          } else {
            this.multiproductWidgetCustomisation = Object.freeze(data.data.multiproductWidgetCustomisation)
            this.retailers = Object.freeze(data.data.retailers)
          }
        })
    }
  },
  created: function() {
    this.loadData()
  }
}
</script>
